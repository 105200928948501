<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoading" />
    <modal
      :class="$style.modal"
      name="product-filters-modal"
      scrollable
      height="auto"
    >
      <ProductsFilters
        v-model="productFilters"
        title="Фильтры всех товаров"
        :options="productsFilterOptions"
        @reset="resetProductFilters"
        @apply="applyProductFilters"
      />
    </modal>
    <modal
      :class="$style.modal"
      name="catalog-product-filters-modal"
      scrollable
      height="auto"
    >
      <ProductsFilters
        v-model="catalogProductFilters"
        title="Фильтры товаров в каталоге"
        :options="catalogProductsFilterOptions"
        @reset="resetCatalogProductFilters"
        @apply="applyCatalogProductFilters"
      />
    </modal>
    <h1>
      Редактировать каталог
      <template v-if="catalogName">"{{ catalogName }}"</template>
    </h1>
    <div :class="$style.wrapper">
      <div>
        <h2 :class="$style.tableTitle">Все товары</h2>
        <vue-good-table
          :columns="productsColumns"
          :rows="products"
          max-height="500px"
          theme="polar-bear"
          styleClass="vgt-table striped"
          fixed-header
        >
          <div slot="emptystate">Ни одного товара не найдено</div>
          <div slot="table-actions" :class="$style.actions">
            <a
              href=""
              title="Добавить отфильтрованные товары"
              @click.prevent="addFilteredProducts"
            >
              <Icon :class="$style.icon" name="plus" />
            </a>
            <Button
              :class="$style.filtersToggler"
              small
              @click="$modal.show('product-filters-modal')"
            >
              Фильтры
              <Icon name="filters" />
              <span
                v-if="appliedProductFiltersCount"
                :class="$style.filtersCount"
              >
                ({{ appliedProductFiltersCount }})
              </span>
            </Button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'actions'">
              <a
                href=""
                title="Добавить товар"
                @click.prevent="addProduct(props.row.id)"
              >
                <Icon :class="$style.icon" name="plus" />
              </a>
            </span>
          </template>
        </vue-good-table>
        <Pagination
          v-if="productsCount >= perPage"
          :curPage="productsPage"
          :lastPage="productsLastPage"
          @go-next="nextProductsPage"
          @go-prev="prevProductsPage"
        />
      </div>
      <div>
        <h2 :class="$style.tableTitle">Товары в каталоге</h2>
        <vue-good-table
          :columns="catalogProductsColumns"
          :rows="catalogProducts"
          max-height="500px"
          theme="polar-bear"
          styleClass="vgt-table striped"
          fixed-header
        >
          <div slot="emptystate">Ни одного товара не добавлено</div>
          <div slot="table-actions" :class="$style.actions">
            <a
              href=""
              title="Удалить отфильтрованные товары"
              @click.prevent="deleteFilteredProducts"
            >
              <Icon :class="$style.icon" name="minus" />
            </a>
            <Button
              :class="$style.filtersToggler"
              small
              @click="$modal.show('catalog-product-filters-modal')"
            >
              Фильтры
              <Icon name="filters" />
              <span
                v-if="appliedCatalogProductFiltersCount"
                :class="$style.filtersCount"
              >
                ({{ appliedCatalogProductFiltersCount }})
              </span>
            </Button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'actions'">
              <a
                href=""
                title="Удалить товар"
                @click.prevent="deleteProduct(props.row.id)"
              >
                <Icon :class="$style.icon" name="minus" />
              </a>
            </span>
          </template>
        </vue-good-table>
        <Pagination
          v-if="catalogProductsCount > perPage"
          :curPage="catalogProductsPage"
          :lastPage="catalogProductsLastPage"
          @go-next="nextCatalogProductsPage"
          @go-prev="prevCatalogProductsPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Pagination from '@/components/atoms/Paginations.vue'
import ProductsFilters from '@/components/moleculs/ProductsFilters.vue'
export default {
  components: {
    Icon,
    Button,
    Loader,
    Pagination,
    ProductsFilters,
  },
  data() {
    return {
      isLoading: false,
      catalogId: '',
      catalogName: '',
      perPage: 20,
      products: [],
      productsPage: 1,
      productsCount: 0,
      catalogProducts: [],
      catalogProductsPage: 1,
      catalogProductsCount: 0,
      productFilters: {
        price: {
          min: null,
          max: null,
        },
        withDiscountPrice: false,
        isAvailable: false,
        groups: [],
        brands: [],
      },
      productsFilterOptions: {
        brands: [],
        groups: [],
        withDiscountPriceCount: 0,
      },
      catalogProductFilters: {
        price: {
          min: null,
          max: null,
        },
        withDiscountPrice: false,
        isAvailable: false,
        groups: [],
        brands: [],
      },
      catalogProductsFilterOptions: {
        brands: [],
        groups: [],
        withDiscountPriceCount: 0,
      },
      productsColumns: [
        {
          label: 'Артикул',
          field: 'vendorСode',
          sortable: false,
        },
        {
          label: 'Назв.',
          field: 'name',
          sortable: false,
        },
        {
          label: 'РРЦ',
          field: 'recommendedPrice',
          sortable: false,
        },
        {
          field: 'actions',
          sortable: false,
        },
      ],
      catalogProductsColumns: [
        {
          label: 'Артикул',
          field: 'vendorСode',
          sortable: false,
        },
        {
          label: 'Назв.',
          field: 'name',
          sortable: false,
        },
        {
          label: 'РРЦ',
          field: 'recommendedPrice',
          sortable: false,
        },
        {
          field: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    productsLastPage() {
      return Math.ceil(this.productsCount / this.perPage)
    },
    catalogProductsLastPage() {
      return Math.ceil(this.catalogProductsCount / this.perPage)
    },
    appliedProductFiltersCount() {
      let count = 0
      if (this.productFilters.price.min !== null) count++
      if (this.productFilters.price.max !== null) count++
      if (this.productFilters.withDiscountPrice) count++
      if (this.productFilters.isAvailable) count++
      if (this.productFilters.isPortfolioAddwine) count++
      count += this.productFilters.groups.length
      count += this.productFilters.brands.length
      return count
    },
    appliedCatalogProductFiltersCount() {
      let count = 0
      if (this.catalogProductFilters.price.min !== null) count++
      if (this.catalogProductFilters.price.max !== null) count++
      if (this.catalogProductFilters.withDiscountPrice) count++
      if (this.catalogProductFilters.isAvailable) count++
      if (this.catalogProductFilters.isPortfolioAddwine) count++
      count += this.catalogProductFilters.groups.length
      count += this.catalogProductFilters.brands.length
      return count
    },
  },
  async created() {
    await this.getCatalog()

    this.getProductsFilterOptions()
    this.getCatalogProductsFilterOptions()
    this.getProductList()
    this.getCatalogProductList()
  },
  methods: {
    async getCatalog() {
      this.isLoading = true

      const result = await delivery.AddwineCore.CatalogsActions.getById(
        this.$route.params.id,
      )

      if (result.error) {
        console.error(result.error)
      } else {
        this.catalogId = result.value.id
        this.catalogName = result.value.name
      }

      this.isLoading = false
    },
    async getProductsFilterOptions() {
      this.isLoading = true

      const result =
        await delivery.AddwineCore.ProductsActions.getFilterOptions(
          null,
          this.catalogId,
        )

      if (result.error) {
        console.error(result.error)
      } else {
        this.productsFilterOptions = result.value
      }

      this.isLoading = false
    },
    async getCatalogProductsFilterOptions() {
      this.isLoading = true

      const result =
        await delivery.AddwineCore.CatalogsActions.getProductsFilterOptions(
          this.catalogId,
        )

      if (result.error) {
        console.error(result.error)
      } else {
        this.catalogProductsFilterOptions = result.value
      }

      this.isLoading = false
    },
    async getProductList() {
      this.isLoading = true

      const result = await delivery.AddwineCore.ProductsActions.getList({
        page: this.productsPage,
        limit: this.perPage,
        exceptDealerCatalogId: this.catalogId,
        ...this.productFilters,
      })

      if (result.error) {
        console.error(result.error)
      } else {
        this.products = result.value.data
        this.productsCount = result.value.meta.count
      }

      this.isLoading = false
    },
    async getCatalogProductList() {
      this.isLoading = true

      const result = await delivery.AddwineCore.CatalogsActions.getProductList(
        this.catalogId,
        {
          page: this.catalogProductsPage,
          limit: this.perPage,
          ...this.catalogProductFilters,
        },
      )

      if (result.error) {
        console.error(result.error)
      } else {
        this.catalogProducts = result.value.data
        this.catalogProductsCount = result.value.meta.count
      }

      this.isLoading = false
    },
    async nextProductsPage() {
      if (this.productsPage < this.productsLastPage) {
        this.productsPage++
        await this.getProductList()
      }
    },
    async prevProductsPage() {
      if (this.productsPage > 1) {
        this.productsPage--
        await this.getProductList()
      }
    },
    async nextCatalogProductsPage() {
      if (this.catalogProductsPage < this.catalogProductsLastPage) {
        this.catalogProductsPage++
        await this.getCatalogProductList()
      }
    },
    async prevCatalogProductsPage() {
      if (this.catalogProductsPage > 1) {
        this.catalogProductsPage--
        await this.getCatalogProductList()
      }
    },
    async resetProductFilters() {
      this.productFilters.price.min = null
      this.productFilters.price.max = null
      this.productFilters.withDiscountPrice = false
      this.productFilters.isAvailable = false
      this.productFilters.isPortfolioAddwine = false
      this.productFilters.groups = []
      this.productFilters.brands = []
      this.$modal.hide('product-filters-modal')
      this.productsPage = 1
      await this.getProductList()
    },
    async resetCatalogProductFilters() {
      this.catalogProductFilters.price.min = null
      this.catalogProductFilters.price.max = null
      this.catalogProductFilters.withDiscountPrice = false
      this.catalogProductFilters.isAvailable = false
      this.catalogProductFilters.isPortfolioAddwine = false
      this.catalogProductFilters.groups = []
      this.catalogProductFilters.brands = []
      this.$modal.hide('catalog-product-filters-modal')
      this.catalogProductsPage = 1
      await this.getCatalogProductList()
    },
    async applyProductFilters() {
      this.$modal.hide('product-filters-modal')
      this.productsPage = 1
      await this.getProductList()
    },
    async applyCatalogProductFilters() {
      this.$modal.hide('catalog-product-filters-modal')
      this.catalogProductsPage = 1
      await this.getCatalogProductList()
    },
    async addProduct(id) {
      this.isLoading = true

      const result = await delivery.AddwineCore.CatalogsActions.addProduct(
        this.catalogId,
        id,
      )

      if (result.error) {
        console.error(result.error)
        if (
          result.error === 'PRODUCT ALREADY EXIST IN RECEIVED DEALER CATALOG'
        ) {
          alert('Этот товар уже добавлен в текущий каталог!')
        }
      } else {
        this.getProductsFilterOptions()
        this.getCatalogProductsFilterOptions()
        this.productsPage = 1
        this.catalogProductsPage = 1
        this.getProductList()
        this.getCatalogProductList()
      }

      this.isLoading = false
    },
    async deleteProduct(id) {
      this.isLoading = true

      const result = await delivery.AddwineCore.CatalogsActions.deleteProduct(
        this.catalogId,
        id,
      )

      if (result.error) {
        console.error(result.error)
      } else {
        this.getProductsFilterOptions()
        this.getCatalogProductsFilterOptions()
        this.productsPage = 1
        this.catalogProductsPage = 1
        this.getProductList()
        this.getCatalogProductList()
      }

      this.isLoading = false
    },
    async addFilteredProducts() {
      if (
        !confirm(
          'Добавить отфильтрованные товары в каталог? Это может занять несколько минут.',
        )
      ) {
        return
      }

      this.isLoading = true

      const result =
        await delivery.AddwineCore.CatalogsActions.addFilteredProducts(
          this.catalogId,
          this.productFilters,
        )

      if (result.error) {
        console.error(result.error)
      } else {
        alert('Отфильтрованные товары успешно добавлены в каталог.')
        this.getProductsFilterOptions()
        this.getCatalogProductsFilterOptions()
        this.productsPage = 1
        this.catalogProductsPage = 1
        this.getProductList()
        this.getCatalogProductList()
      }

      this.isLoading = false
    },
    async deleteFilteredProducts() {
      if (
        !confirm(
          'Удалить отфильтрованные товары из каталога? Это может занять несколько минут.',
        )
      ) {
        return
      }

      this.isLoading = true

      const result =
        await delivery.AddwineCore.CatalogsActions.deleteFilteredProducts(
          this.catalogId,
          this.catalogProductFilters,
        )

      if (result.error) {
        console.error(result.error)
      } else {
        alert('Отфильтрованные товары успешно удалены из каталога.')
        this.getProductsFilterOptions()
        this.getCatalogProductsFilterOptions()
        this.productsPage = 1
        this.catalogProductsPage = 1
        this.getProductList()
        this.getCatalogProductList()
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .modal {
    z-index: 1010;
  }
  .wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    .icon {
      fill: $light-gray;
    }
    .tableTitle {
      margin-bottom: 0.5rem;
    }
    .actions {
      display: flex;
      align-items: center;
      > a {
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
      }
      .filtersToggler {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        > svg {
          margin-left: 0.5rem;
        }
        .filtersCount {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
